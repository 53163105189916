import { view } from '@risingstack/react-easy-state'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import { AddressTable, ClientTenants, MoveInOutTenants, Summary } from './shared'
import endpoints from '../../../helpers/endpoints'


export default view(function LTAPAnalysisWorkflow() {
    const [summaryData, setSummaryData] = useState({})
    const [translations, setTranslations] = useState({})
    const [status, setStatus] = useState('null')
    const [propRef, setPropRef] = useState()
    const [workflowItems, setWorkflowItems] = useState([])
    const [clientId, setClientId] = useState()
    const [workflowIndex, setWorkflowIndex] = useState(1)

    const clearSummaryData = () => {
        setSummaryData({})
        setPropRef('')
        setClientId('')
    }

    const _cur = useMemo(() => {
        return workflowItems.findIndex(_ => _ === propRef);
    }, [workflowItems, propRef]);

    // Get list of ltap_property_reference numbers for workflow
    const getWorkflowItems = useCallback(() => {
        request(true).get(endpoints.LTAP_ANALYSIS_WF_ITEMS).then(e => {
            setWorkflowItems(e.data);
            if (e.data.length > 0) {
                setPropRef(e.data[_cur && _cur !== -1 ? _cur : 0])
            } else {
                setPropRef()
            }
        })
    }, [_cur])

    useEffect(() => {
        request(true).get('/translate?key=ltap_analysis')
            .then(e => {
                setTranslations(e.data)
            })
    }, [])

    return (
        <GenericPageContainer
            containerWidth="92vw"
            title={`${translations['module_title'] || ''} - Workflow`}
            titleIconClass={"fak fa-id-house-icon fa-2x colour-primary"}
            titleRight={
                <div style={{ marginBottom: '10px' }}>
                    <Link className="navigate" to="/portal/ltap-analysis">To Summary</Link>
                    <Link className="navigate" to="/portal/ltap-analysis/search">To Search</Link>
                </div>
            }
        >
            <div className='lta' style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'grid', gridTemplateColumns: "1.3fr 4fr", gap: 10, paddingBottom: "15px", borderBottom: "4px dashed #e8e8e8" }}>
                    <Summary 
                        translations={translations} 
                        summaryData={summaryData} 
                        setSummaryData={setSummaryData} 
                        getWorkflowItems={getWorkflowItems}
                        status={status} 
                        setStatus={setStatus} 
                        clearSummaryData={clearSummaryData}
                        workflow={true}
                    />
                    <AddressTable 
                        translations={translations} 
                        summaryData={summaryData} 
                        setSummaryData={setSummaryData} 
                        workflowIndex={workflowIndex}
                        setWorkflowIndex={setWorkflowIndex}
                        _cur={_cur}
                        propRef={propRef}
                        setPropRef={setPropRef}
                        workflowItems={workflowItems}
                        getWorkflowItems={getWorkflowItems}
                        clientId={clientId}
                        setClientId={setClientId}
                        workflow={true} 
                    />
                </div>
                
                <div style={{ display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10, paddingTop: 5 }}>
                    <ClientTenants translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} maxTenants={5} />
                </div>

                <div style={{ display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10 }}>
                    <MoveInOutTenants translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} maxTenants={5} mode='in' />
                </div>
                <div style={{ display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10 }}>
                    <MoveInOutTenants translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} maxTenants={5} mode='out' />
                </div>
            </div>
        </GenericPageContainer>
    )
})
