import React from 'react'

export function OccupierFootprintEvidenceSecond({ data }) {
    return (
        <table className="table borders">
            <thead>
                <tr>
                    <th>
                        Occupier Footprint
                    </th>
                    <th>
                        Evidence
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Count of telco/media accounts
                    </td>
                    <td>
                        {data.customer ? data.customer.Telco_Accounts : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Count of home credit accounts
                    </td>
                    <td>
                        {data.customer ? data.customer.Home_Credit_Accounts : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Count of mail order accounts
                    </td>
                    <td>
                        {data.customer ? data.customer.Mail_Order : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Count of revolving facilities
                    </td>
                    <td>
                        {data.customer ? data.customer.Revolving_Facilities : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Count of other facilities
                    </td>
                    <td>
                        {data.customer ? data.customer.Other_Accounts : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Count of recent credit searches
                    </td>
                    <td>
                        {data.customer ? data.customer.Credit_Searches : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Earliest date linked to property
                    </td>
                    <td>
                        {data.customer ? (data.customer.Earliest_Date !== null ? data.customer.Earliest_Date : '-') : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Occupier Score
                    </td>
                    <td>
                        {data.customer ? data.customer.Occupier_Score + " Stars" : ''}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export function OccupierFootprintEvidenceFirst({ data }) {
    return (
        <table className="table borders">
            <thead>
                <tr>
                    <th>
                        Occupier Footprint
                    </th>
                    <th>
                        Evidence
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Listed on electoral roll
                    </td>
                    <td>
                        {data.customer ? data.customer.Electoral_Roll : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Listed on BT register
                    </td>
                    <td>
                        {data.customer ? data.customer.BT_Line : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Count of secured loans
                    </td>
                    <td>
                        {data.customer ? data.customer.Secured_Loans : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        GAS redirect in place
                    </td>
                    <td>
                        {data.customer ? data.customer.GAS_Redirect : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        NCoA redirect in place
                    </td>
                    <td>
                        {data.customer ? data.customer.NCOA_Redirect : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Count of bank accounts
                    </td>
                    <td>
                        {data.customer ? data.customer.Bank_Accounts : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Count of unsecured loans
                    </td>
                    <td>
                        {data.customer ? data.customer.Unsecured_Loans : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Count of utility accounts
                    </td>
                    <td>
                        {data.customer ? data.customer.Utility_Accounts : ''}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export function OccupierCOTAlertTopContent({ data }) {
    return (
        <>
            {data.customer && data.customer.Surname !== false ?
                <div className="name-grid">
                    <div className="data-row">
                        <div className="data-row-title">
                            Title
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={data.customer ? `${data.customer.Title}` : ''} />
                    </div>
                    <div className="data-row">
                        <div className="data-row-title">
                            Forename
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={data.customer ? `${data.customer.Forename}` : ''} />
                    </div>
                    <div className="data-row">
                        <div className="data-row-title">
                            Surname
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={data.customer ? `${data.customer.Surname}` : ''} />
                    </div>
                </div>
                :
                <div className="data-row">
                    <div className="data-row-title">
                        {data.translations ? data.translations['name'] : ''}
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={data.customer ? `${data.customer.Title}` : ''} />
                </div>
            }
            <div className="left-grid-bottom">
                <div className="data-row">
                    <div className="data-row-title">
                        {data.translations ? data.translations['date_of_birth'] : ''}
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.Date_of_Birth : ''} />
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                        {data.translations ? data.translations['landline'] : ''}
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.Landline : ''} />
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                        {data.translations ? data.translations['mobile'] : ''}
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.Mobile : ''} />
                </div>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    {data.translations ? data.translations['client_address'] : ''}
                </div>
                <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.SPID_Address : ''} />
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    {data.translations ? data.translations['matched_address'] : ''}
                </div>
                <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.AddressBase_Address : ''} />
            </div>
            <div className="left-grid-bottom">
                <div className="data-row">
                    <div className="data-row-title">
                        {data.translations ? data.translations['address_score'] : ''}
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.Address_Quality : ''} />
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                        {data.translations ? data.translations['uprn'] : ''}
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.UPRN : ''} />
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                        {data.translations ? data.translations['debt_brand'] : ''}
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.Debt_Banding : ''} />
                </div>
            </div>
        </>
    )
}

export default function SharedComponents() {
    return (
        <div>
            
        </div>
    )
}


export function OccupierCOTAlertRight({ data }) {
    return (
        <>
            <div className="data-row">
                <div className="data-row-title">
                    {data.translations ? data.translations['email'] : ''}
                </div>
                <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.Email : ''} />
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    {data.translations ? data.translations['void_age_band'] : ''}
                </div>
                <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.Voids_Age_Band : ''} />
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    {data.translations ? data.translations['status'] : ''}
                </div>
                <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.Account_Status : ''} />
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    {data.translations ? data.translations['tele_no_avail'] : ''}
                    {/* TODO: Do good */}
                </div>
                <input type="text" className="data-row-field" disabled={true} value={data.customer ? data.customer.Phone_Number : ''} />
            </div>
        </>
    )
}
