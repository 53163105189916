import React, { useEffect, useState } from 'react'
import PropertyMap from '../../../components/property-map/property-map';
import endpoints from '../../../helpers/endpoints';
import request from '../../../helpers/request';
import Select from "react-select";

export const DataRow = ({translations, title, value, customer, customValue, style}) => {
    return (
        <div className="data-row" style={style ? style : null}>
            <div className="data-row-title">
            { translations ? translations[title] : '' }
            </div>
            <input type="text" className="data-row-field" disabled={true} value={ !customValue ? customer ? customer[value] ? `${customer[value]}` : '' : '' : customValue }/>
        </div>
    )
}

export const Title = ({title, disabled, colour}) => {
    return (
        <div className={`cha-data-row-title ${colour ? `background-${colour}` : ''} ${disabled ? 'disabled' : ''}`}>
            {title}
        </div>
    )
}

export const GenericTitledTextBox = ({children, title}) => {
    return (
        <div style={{display: 'grid', gridTemplateRows: 'min-content auto'}}>
            { 
                title ?
                    <div className="background-primary colour-white" style={{borderTopLeftRadius: 5, borderTopRightRadius: 5, padding: 7, fontSize: 13, fontWeight: 'bold'}}>
                        {title}
                    </div>
                : null 
            }
            <div className="background-white colour-black" style={{padding: 7}}>
                {children}
            </div>
        </div>
    )
}

export const matchColour = (state) => {
    switch (state) {
        case 'Append':
            return 'blue'
        case 'Change':
            return 'red'
        case 'Match':
            return 'logogreen'
        case 'Not Found':
            return 'red'
        default:
            return null;
    }
}

export function LeftColumn({ state, children }) {
    return (
        <div style={{display: 'grid', gridTemplateColumns: '2fr 2fr 1fr', gap: 15}}>
            {/* TOP */}
            <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
                <Title title="Client" />
                {/* This is the search field */}
                <div style={{display: 'grid', gridAutoRows: '1fr', gap: 10, height: '100%'}}>
                    {
                        children
                    }
                    <DataRow translations={state.translations} customer={state.customer} value={'Client_Co_Name'} title={'client_name'} />
                    <DataRow translations={state.translations} customer={state.customer} value={'Client_Co_Address'} title={'client_address'} />
                    <DataRow translations={state.translations} customer={state.customer} value={'Client_SIC'} title={'client_sic'} />
                    <DataRow translations={state.translations} customer={state.customer} value={'Client_Co_No'} title={'client_number'} />
                </div>
            </div>
            <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
                <Title title="Companies House" />
                <div style={{display: 'grid', gridAutoRows: '1fr', gap: 10, height: '100%'}}>
                    <DataRow translations={state.translations} customer={state.customer} value={'CP_Co_Status'} title={'Status'} style={{height: 36.67}} />
                    <DataRow translations={state.translations} customer={state.customer} value={'CP_Co_Name'} title={'company_name'} />
                    <DataRow translations={state.translations} customer={state.customer} value={'CP_Co_Address'} title={'company_address'} />
                    <DataRow translations={state.translations} customer={state.customer} value={'CP_Co_SIC_1980'} title={'company_sic'} />
                    <DataRow translations={state.translations} customer={state.customer} value={'CP_Co_No'} title={'company_number'} />
                </div>
            </div>
            <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
                <Title title="Match Status" />
                <div style={{display: 'grid', gridAutoRows: '1fr', gap: 10, height: '100%'}}>
                    <Title colour={state.customer ? matchColour(state.customer.CP_CO_Status_Status) : null} title={state.customer ? state.customer.CP_CO_Status_Status : 'N/A'} disabled={true} />
                    <Title colour={state.customer ? matchColour(state.customer.CP_CO_Name_Status) : null} title={state.customer ? state.customer.CP_CO_Name_Status : 'N/A'} disabled={true} />
                    <Title colour={state.customer ? matchColour(state.customer.CP_CO_Address_Status) : null} title={state.customer ? state.customer.CP_CO_Address_Status : 'N/A'} disabled={true} />
                    <Title colour={state.customer ? matchColour(state.customer.CP_CO_SIC_Status) : null} title={state.customer ? state.customer.CP_CO_SIC_Status : 'N/A'} disabled={true} />
                    <Title colour={state.customer ? matchColour(state.customer.CP_CO_No_Status) : null} title={state.customer ? state.customer.CP_CO_No_Status : 'N/A'} disabled={true} />
                </div>
            </div>
        </div>
    )
}

const NeedsCustomer = () => {
    return (
        <p>Search for an account to get information.</p>
    )
}

export const BottomRow = ({customer}) => {
    return (
        <div className="secondary-grid">
            {/* <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null }/> */}
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10, height: '100%'}}>
            <PropertyMap address={customer ? customer.CP_Co_Address : ''} />
                <div style={{display: 'grid', gridTemplateRows: '1fr 1fr', gap: 10, height: '100%', flexGrow: 1}}>
                    <GenericTitledTextBox title="Accounts / Confirmation Status">
                        {
                            customer ? <>
                                <p className="font-14">Incorporated <strong>{customer.CP_Incorporate_Date}</strong></p>
                                <ul className="font-14">
                                    <li>Next accounts made up to <strong>{customer.CP_Accounts_Made_To}</strong></li>
                                    <li>due by <strong>{customer.CP_Accounts_Next_Due}</strong></li>
                                    <li>Next statement date <strong>{customer.CP_Accounts_Conf_Date}</strong></li>
                                    <li>due by <strong>{customer.CP_Accounts_Next_Conf_Date}</strong></li>
                                    <li>Dissolved on <strong>{customer.CP_Date_Of_Cessation}</strong></li>
                                </ul>
                            </> : <NeedsCustomer />
                        }
                    </GenericTitledTextBox>
                    <GenericTitledTextBox title="Charges">
                        {
                            customer ? <>
                                <p className="font-14"><strong>{customer.CP_Charges} charge(s) registered</strong></p>
                                <p className="font-14">{customer.CP_Charges_Box}</p>
                            </> : <NeedsCustomer />
                        }
                    </GenericTitledTextBox>
                </div>
                <div style={{display: 'grid', gridTemplateRows: '1fr 1fr', gap: 10, height: '100%', flexGrow: 1}}>
                    <GenericTitledTextBox title="Directors">
                        {
                            customer ? <>
                                <ol className="font-14">
                                    { customer.CP_Director_1 ? <li>{customer.CP_Director_1}</li> : null }
                                    { customer.CP_Director_2 ? <li>{customer.CP_Director_2}</li> : null }
                                    { customer.CP_Director_3 ? <li>{customer.CP_Director_3}</li> : null }
                                    { customer.CP_Director_4 ? <li>{customer.CP_Director_4}</li> : null }
                                    { customer.CP_Director_5 ? <li>{customer.CP_Director_5}</li> : null }
                                </ol>
                            </> : <NeedsCustomer />
                        }
                    </GenericTitledTextBox>
                    <GenericTitledTextBox title="Debt Status">
                        {
                            customer ? <>
                                <ul>
                                    <li><a href={customer.CP_URL} target={"_blank"} rel="noreferrer">{customer.CP_URL}</a></li>
                                </ul>
                                <table className="table squish" style={{tableLayout: 'fixed', width: '100%'}}>
                                    <thead>
                                        <tr>
                                            <th style={{padding: 2}} className="font-14">Total Debt</th>
                                            <th style={{padding: 2}} className="font-14 width-30">0-30</th>
                                            <th style={{padding: 2}} className="font-14 width-30">31-60</th>
                                            <th style={{padding: 2}} className="font-14 width-30">61-90</th>
                                            <th style={{padding: 2}} className="font-14 width-30">91-180</th>
                                            <th style={{padding: 2}} className="font-14 width-30">181-365</th>
                                            <th style={{padding: 2}} className="font-14 width-30">365+</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td className="font-14">{customer.Client_Debt_Total}</td>
                                            <td className="font-14">{customer.Client_Debt_0_30}</td>
                                            <td className="font-14">{customer.Client_Debt_181_365}</td>
                                            <td className="font-14">{customer.Client_Debt_31_60}</td>
                                            <td className="font-14">{customer.Client_Debt_61_91}</td>
                                            <td className="font-14">{customer.Client_Debt_91_180}</td>
                                            <td className="font-14">{customer.Client_Debt_Over_365}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </> : <NeedsCustomer />
                        }
                    </GenericTitledTextBox>
                </div>
            </div>
        </div>
    )
}

export const RightColumn = ({ date, customer, filterArrays, filterStats }) => {
    const [stats, setStats] = useState(null)
    const [matchStats, setMatchStats] = useState(null);
    const [filingStats, setFilingStats] = useState(null);
    const [companyStats, setCompanyStats] = useState(null);
    const [wholesalers, setWholesalers] = useState([]);
    const [period, setPeriod] = useState('1 Day');
    useEffect(() => {
        request(true).get(endpoints.COMPANIES_HOUSE_ALERTS_WHOLESALER + `?date=${date}`).then(e => {
            setWholesalers(e.data)
        })
        request(true).get(endpoints.COMPANIES_HOUSE_ALERTS_STATS + `?date=${date}&period=${period}${filterArrays.Wholesaler ? `&wholesalers=${Object.keys(filterArrays.Wholesaler)}` : ''}`).then(e => {
            setStats(e.data)
        })
        request(true).get(endpoints.COMPANIES_HOUSE_ALERTS_STATS_QUALITY + `?date=${date}${filterArrays.Wholesaler ? `&wholesalers=${Object.keys(filterArrays.Wholesaler)}` : ''}`).then(e => {
            setMatchStats(e.data)
        })
        request(true).get(endpoints.COMPANIES_HOUSE_ALERTS_STATS_FILING + `?date=${date}${filterArrays.Wholesaler ? `&wholesalers=${Object.keys(filterArrays.Wholesaler)}` : ''}`).then(e => {
            setFilingStats(e.data)
        })
        request(true).get(endpoints.COMPANIES_HOUSE_ALERTS_STATS_COMPANY + `?date=${date}${filterArrays.Wholesaler ? `&wholesalers=${Object.keys(filterArrays.Wholesaler)}` : ''}`).then(e => {
            setCompanyStats(e.data)
        })
    }, [date, period, filterArrays.Wholesaler])
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 15, paddingTop: 32}}>
            <GenericTitledTextBox title={"Wholesaler"}>
                <Select
                    isMulti
                    options={wholesalers.map((option) => {
                        return (
                            {label: option, value: option}
                        )
                    })}
                    onChange={(selectedOptions) => filterStats('Wholesaler', selectedOptions.map(option => option.value))}
                    isSearchable
                    placeholder={`Select Wholesaler`}
                />
            </GenericTitledTextBox>
            <GenericTitledTextBox>
                {
                    stats ? <>
                        <table className="table squish">
                            <thead>
                                <tr>
                                    <th className="font-14">New Status</th>
                                    <th className="font-14">Volume</th>
                                    <th className="font-14">Debt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    stats.map(stat => {
                                        return (
                                            <tr>
                                                <td className="font-14">{stat.CP_Co_Status}</td>
                                                <td className="font-14" style={ filterArrays.CP_Co_Status ? filterArrays.CP_Co_Status[stat.CP_Co_Status] ? { fontWeight: "bold"} : {fontWeight: "normal"} : {fontWeight: "normal"}} onClick={() => filterStats("CP_Co_Status", stat.CP_Co_Status)}>{new Intl.NumberFormat().format(stat.volume)}</td>
                                                <td className="font-14" style={ filterArrays.CP_Co_Status ? filterArrays.CP_Co_Status[stat.CP_Co_Status] ? { fontWeight: "bold"} : {fontWeight: "normal"} : {fontWeight: "normal"}} onClick={() => filterStats("CP_Co_Status", stat.CP_Co_Status)}>£{new Intl.NumberFormat().format(stat.debt)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </> : 'Loading...'
                }
            </GenericTitledTextBox>
            <GenericTitledTextBox>
                {
                    matchStats ? <>
                        <table className="table squish">
                            <thead>
                                <tr>
                                    <th style={{padding: 3}}>Co. Details</th>
                                    {
                                        Object.keys(matchStats['Number']).map(k => {
                                            return <th style={{padding: 3}} className="font-14">{k}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                    {
                                        Object.keys(matchStats).map(k => {
                                            let qualityFilter = {}
                                            let filterKey = ""
                                            switch (k) {
                                                case "Number":
                                                    qualityFilter = filterArrays.CP_CO_No_Status ? filterArrays.CP_CO_No_Status : {}
                                                    filterKey = "CP_CO_No_Status"
                                                    break;
                                                case "Name":
                                                    qualityFilter = filterArrays.CP_CO_Name_Status ? filterArrays.CP_CO_Name_Status : {}
                                                    filterKey = "CP_CO_Name_Status"
                                                    break;
                                                case "Reg. Address":
                                                    qualityFilter = filterArrays.CP_CO_Address_Status ? filterArrays.CP_CO_Address_Status : {}
                                                    filterKey = "CP_CO_Address_Status"
                                                    break;
                                                case "SIC Code":
                                                    qualityFilter = filterArrays.CP_CO_SIC_Status ? filterArrays.CP_CO_SIC_Status : {}
                                                    filterKey = "CP_CO_SIC_Status"
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return <tr>
                                                <td className="font-14">{k}</td>
                                                <td className="font-14" style={ qualityFilter.Match ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={() => filterStats(filterKey, "Match")}>{new Intl.NumberFormat().format(matchStats[k].Match)}</td>
                                                <td className="font-14" style={ qualityFilter.Change ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={() => filterStats(filterKey, "Change")}>{new Intl.NumberFormat().format(matchStats[k].Change)}</td>
                                                <td className="font-14" style={ qualityFilter.Append ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={() => filterStats(filterKey, "Append")}>{new Intl.NumberFormat().format(matchStats[k].Append)}</td>
                                                <td className="font-14" style={ qualityFilter['Not Found'] ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={() => filterStats(filterKey, "Not Found")}>{new Intl.NumberFormat().format(matchStats[k]['Not Found'])}</td>
                                            </tr>
                                        })
                                    }
                            </tbody>
                        </table>
                    </> : 'Loading...'
                }
            </GenericTitledTextBox>
            <GenericTitledTextBox>
                {
                    filingStats ? <>
                        <table className="table squish">
                            <thead>
                                <tr>
                                    <th className="font-14">Filings</th>
                                    <th className="font-14">Up to Date</th>
                                    <th className="font-14">Due &lt;30 Days</th>
                                    <th className="font-14">Overdue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(filingStats).map(k => {
                                        let filingFilter = {}
                                        let filterKey = ""
                                        switch (k) {
                                            case "Annual Accounts":
                                                filingFilter = filterArrays.CP_Annual_Acc_Status ? filterArrays.CP_Annual_Acc_Status : {}
                                                filterKey = "CP_Annual_Acc_Status"
                                                break;
                                            case "Confirmation Statement":
                                                filingFilter = filterArrays.CP_Annual_Conf_Status ? filterArrays.CP_Annual_Conf_Status : {}
                                                filterKey = "CP_Annual_Conf_Status"
                                                break;
                                            default:
                                                break;
                                        }
                                        return (
                                            <tr>
                                                <td className="font-14">{k}</td>
                                                <td className="font-14" style={ filingFilter['Up to Date'] ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={() => filterStats(filterKey, "Up to Date")}>{new Intl.NumberFormat().format(filingStats[k]['Up to Date'])}</td>
                                                <td className="font-14" style={ filingFilter['Due <30 Days'] ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={() => filterStats(filterKey, "Due <30 Days")}>{new Intl.NumberFormat().format(filingStats[k]['Due <30 Days'])}</td>
                                                <td className="font-14" style={ filingFilter['Overdue'] ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={() => filterStats(filterKey, "Overdue")}>{new Intl.NumberFormat().format(filingStats[k]['Overdue'])}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </> : 'Loading...'
                }
            </GenericTitledTextBox>
            <GenericTitledTextBox>
                {
                    companyStats ? <>
                        <table className="table squish">
                            <thead>
                                <tr>
                                    <th className="font-14">Company Details</th>
                                    <th className="font-14">Match</th>
                                    <th className="font-14">No Match</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(companyStats).map(k => {
                                        let companyFilter = {}
                                        let filterKey = ""
                                        switch (k) {
                                            case "Name":
                                                companyFilter = filterArrays.CH_Name_Match ? filterArrays.CH_Name_Match : {}
                                                filterKey = "CH_Name_Match"
                                                break;
                                            case "Number":
                                                companyFilter = filterArrays.CH_Number_Match ? filterArrays.CH_Number_Match : {}
                                                filterKey = "CH_Number_Match"
                                                break;
                                            case "Address":
                                                companyFilter = filterArrays.CH_Address_Match ? filterArrays.CH_Address_Match : {}
                                                filterKey = "CH_Address_Match"
                                                break;
                                            case "Director":
                                                companyFilter = filterArrays.CH_Directors_Match ? filterArrays.CH_Directors_Match : {}
                                                filterKey = "CH_Directors_Match"
                                                break;
                                            default:
                                                break;
                                        }
                                        return (
                                            <tr>
                                                <td className="font-14">{k}</td>
                                                <td className="font-14" style={ companyFilter['Yes'] ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={() => filterStats(filterKey, "Yes")}>{new Intl.NumberFormat().format(companyStats[k]['Yes'])}</td>
                                                <td className="font-14" style={ companyFilter['No'] ? { fontWeight: "bold"} : {fontWeight: "normal"}} onClick={() => filterStats(filterKey, "No")}>{new Intl.NumberFormat().format(companyStats[k]['No'])}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </> : 'Loading...'
                }
            </GenericTitledTextBox>
        </div>
    )
}