import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './affordabilityAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
    withRouter(
        class AffordabilityAlertsWorkflow extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customers: [undefined, undefined, undefined, undefined],
                    selectedCustomer: 0,
                    message: '',
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    workflow: [],
                    workflowIndex: null,
                    workflowLength: 0,
                    disableActions: false,
                    translations: {},
                    household: null
                }
            }

            componentDidMount() {
                useWSProduct('affordability-alerts');// eslint-disable-line
                request(true).get('/translate?key=affordability_alerts').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                request(true).get(endpoints.AFFORDABILITY_ALERTS_WORKFLOW).then(r => {
                    this.setState({
                        workflow: r.data,
                        workflowIndex: 0,
                        workflowLength: r.data.length,
                        accountNumber: r.data[0],
                    }, () => {
                        this.getData();
                    });
                }).catch(e => {
                    console.error(e);
                });
            }

            componentWillUnmount() {
                useWSProduct('affordability-alerts', 'unlisten');// eslint-disable-line
            }

            getData = () => {
                this.setState({
                    customers: [undefined, undefined, undefined, undefined],
                    household: null
                })
                request(true).get(`${endpoints.AFFORDABILITY_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    console.log(r.data.customers);
                    if (!Array.isArray(r.data.customers)) r.data.customers = [r.data.customers];
                    while (r.data.customers.length < 4) { r.data.customers.push(undefined)}
                    this.setState({
                        // customers: r.data.customers.sort((a, b) => {
                        //     if (a["Residency_Status"] === "Primary") return -1;
                        //     if (b["Billing_Flag"] === "Primary") return 1;
                        //     if (a["Billing_Flag"] === "Associated") return -1;
                        //     if (b["Billing_Flag"] === "Associated") return 1;
                        //     return a["Residency_Status"] - b["Residency_Status"];
                        // }),
                        customers: r.data.customers.sort((a, b) => {
                            const order = ['Primary', 'Associate', 'New', 'Previous']
                            return order.indexOf(a.Residency_Status) - order.indexOf(b.Residency_Status)
                        }),
                        household: r.data.household,
                        showMap: true,
                        purchasable: r.data[0] && r.data[0].Date_Bought === null,
                        shouldRefreshMap: true,
                        selectedCustomer: 0,
                    }, () => {
                        this.setState({
                            shouldRefreshMap: false,
                            disableActions: false,
                        });
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            purchaseCustomer = () => {
                this.setState({disableActions: true}, () => {
                    request(true).post(`${endpoints.AFFORDABILITY_ALERTS_BUY_CUSTOMER}`, {
                        "customer": this.state.customer.Account_Number
                    }).then(r => {
                        const customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);
                        if (this.state.customer.Purchasable_Message) window.alert(this.state.customer.Purchasable_Message)

                        this.setState({
                            workflow: customers,
                            workflowIndex: this.state.workflowIndex - 1,
                            workflowLength: customers.length,
                            customer: r.data.customer,
                            message: r.data.customer ? '' : 'Customer Does Not Exist',
                            showMap: true,
                            purchasable: r.data.customer && r.data.customer.Date_Bought === null
                        });
                    }).catch(e => {
                        console.log(e);
                    })
                });
            }

            excludeCustomer = () => {
                if (!this.state.customer || !this.state.customer.Account_Number) return;
                this.setState({disableActions: true}, () => {
                    request(true).post(endpoints.AFFORDABILITY_ALERTS_EXCLUDE_CUSTOMER, {
                        customer: this.state.customer.Account_Number
                    }).then(r => {
                        const customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowLength: customers.length,
                            accountNumber: customers[this.state.workflowIndex],
                        }, () => {
                            this.getData();
                        });
                    }).catch(e => {
                        console.error(e);
                    });
                });
            }

            workflowNext = () => {
                if (this.state.workflowIndex + 1 > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex + 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex + 1]
                }, () => {
                    this.getData();
                });
            }

            workflowPrev = () => {
                if (this.state.workflowIndex > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex - 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex - 1]
                }, () => {
                    this.getData();
                });
            }

            setSelectedCustomer = (index) => {
                this.setState({
                    selectedCustomer: index
                })
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="affordability-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title={`${this.state.translations['module_title'] ? this.state.translations['module_title'] : ''} - Workflow`} titleRight={
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Link className="navigate" to='/portal/affordability-alerts/'>
                                        To Overview
                                    </Link>
                                    <Link className="navigate" to='/portal/affordability-alerts/search'>
                                        To Search
                                    </Link>
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="affordability-alerts">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row with-buttons tvaa-data-row-title">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['account_number'] : '' }
                                            </div>
                                            <div style={{ position: 'relative', flex: 'auto' }}>
                                                <input disabled={true} type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } style={{ width: 'calc(100% - 15px)', height: '100%' }}/>
                                                <span className="message">{ this.state.workflowIndex + 1 }/{ this.state.workflowLength }</span>
                                            </div>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '15px', marginLeft: '15px' }}>
                                                <button className="button background-primary colour-white" onClick={ this.workflowPrev } disabled={this.state.workflowIndex === 0}>
                                                    <i className="fas fa-angle-left"></i>
                                                </button>
                                                <button className="button background-primary colour-white" onClick={ this.workflowNext } disabled={this.state.workflowIndex === this.state.workflowLength - 1}>
                                                    <i className="fas fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="household">
                                            <div className="left-grid-bottom">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['property_income'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Property_Income : ''}/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['disposable_income'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Household_EDI : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['affordability_band'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.AA_Band : ''}/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['residency_status'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Income_Category : ''}/>
                                                </div>
                                            </div>
                                            <div className="left-grid-bottom">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['current_balance'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Current_Balance : ''}/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['debt_banding'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Debt_Banding : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['account_start_date'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Residency_Age_Band : ''}/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['type'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.household ? this.state.household.Type : ''}/>
                                                </div>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['client_address'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customers[0] ? this.state.customers[0].SPID_Address : '' }/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['matched_address'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customers[0] ? this.state.customers[0].AddressBase_Address : '' }/>
                                            </div>
                                        </div>

                                        { this.state.customers.map((customer, index) => {
                                            return (
                                                <div className="customer">
                                                    <div className="name-grid">
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['name'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? `${customer.Name}` : '' }/>
                                                        </div>
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['date_of_birth'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? `${customer.Date_of_Birth}` : '' }/>
                                                        </div>
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                                { this.state.translations ? this.state.translations['billing_flag'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? customer.Billing_Flag : ''}/>
                                                        </div>
                                                        <button className="select-button" onClick={() => this.setSelectedCustomer(index)}>Select</button>
                                                    </div>
                                                    <div className="left-grid-bottom">
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['income_band'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? customer.Occupier_Income_Category : ''}/>
                                                        </div>
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['disposable_income'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? customer.Occupier_EDI : '' }/>
                                                        </div>
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['affordability_band'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? customer.Occupier_AA_Band : ''}/>
                                                        </div>
                                                        <div className="data-row">
                                                            <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations['residency_status'] : '' }
                                                            </div>
                                                            <input type="text" className="data-row-field" disabled={true} value={ customer ? customer.Residency_Status : ''}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="secondary-grid">
                                    { this.state.showMap ?
                                        <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null } />
                                    :
                                        <>
                                            <div className="map hidden" onClick={ () => { if (this.state.customer) { this.setState({ showMap: true })}} }>
                                                { this.state.customer ? 'Click to show map' : '' }
                                            </div>
                                        </>
                                    }
                                    <table className="table borders">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Occupier Footprint
                                                </th>
                                                <th>
                                                    Evidence
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '200px'}}>
                                                    Listed on electoral roll
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Electoral_Roll : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Listed on BT register
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].BT_Line : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of secured loans
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Secured_Loans : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Named on Title Deed
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Land_Registry : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    GAS redirect in place
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].GAS_Redirect : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    NCoA redirect in place
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].NCOA_Redirect : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of bank accounts
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Bank_Accounts : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of unsecured loans
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Unsecured_Loans : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of utility accounts
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Utility_Accounts : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of telco/media accounts
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Telco_Accounts : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of home credit accounts
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Home_Credit_Accounts : ''  : '' }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table borders">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Occupier Footprint
                                                </th>
                                                <th>
                                                    Evidence
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '200px'}}>
                                                    Count of mail order accounts
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Mail_Order : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of revolving facilities
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Revolving_Facilities : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of other facilities
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Other_Accounts : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of recent credit searches
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Credit_Searches : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Company House Director
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].CH_Director : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Data on Demand: Loans
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].DoD_Loans : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Data on Demand: Subscriptions
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].DoD_Subscriptions : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Latest date linked to property
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Latest_Date : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Earliest date linked to property
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? (this.state.customers[this.state.selectedCustomer].Earliest_Date !== null ? this.state.customers[this.state.selectedCustomer].Earliest_Date : '-')  : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Occupier Score
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Occupier_Confidence + " Stars" : ''  : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Occupier Score Change
                                                </td>
                                                <td>
                                                    { this.state.customers ? this.state.customers[this.state.selectedCustomer] ? this.state.customers[this.state.selectedCustomer].Occupier_Change : ''  : '' }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

