import React, { Component } from 'react'
import { view } from '@risingstack/react-easy-state'
import Navigation from '../../../components/navigation/navigation';
import Container from '../../../components/container/container';
import Footer from '../../../components/footer/footer';
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import './CompaniesHouseAlerts.scss';
import { Link, withRouter } from 'react-router-dom';
import request from '../../../helpers/request';
import endpoints from '../../../helpers/endpoints';
import { BarChart, XAxis, YAxis, Legend, Bar, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import S3Modal from '../../../components/s3Modal/S3Modal';
import session from '../../../stores/session';
import hasPermission from '../../../helpers/permissions';
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import HelpVideo from '../../../components/help-video/helpVideo';
import useWSProduct from '../../../helpers/useWSProduct';
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter';
import { RightColumn, Title } from './sharedComponents';
import Text from 'recharts/lib/component/Text';
import DataGroupings from '../../../components/data-groupings/data-groupings';
import ProductIcons from '../../../helpers/productIcons';
import PageTitle from '../../../components/page-header/pageTitle'

const localised = Intl.NumberFormat('en-GB');
export const CustomizedAxisTickMultiLine = ({x, y, payload}) => {
    return (
       <Text x={x} y={y} width={120} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
    )
};
export default view(
    withRouter(
        class CompaniesHouseAlerts extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    dataReady: false,
                    dates: [],
                    date: undefined,
                    data: {
                        keys: [],
                        data: [],
                    },
                    graphData: [],
                    group: null,
                    filters: {
                        Deed_Type: null,
                        Consumption: [],
                        New_Connection: null,
                        IMD: [],
                        Voids_Age_Band: [],
                        Occupier_Score: [],
                        Assigned: null,
                        filteredStats: [],
                    },
                    filterArrays: {
                        IMD: {},
                        Voids_Age_Band: {},
                        Occupier_Score: {},
                        Consumption: {},
                    },
                    tableOrGraph: 'graph',
                    colours: [
                        "#445469",
                        "var(--primary)",
                        "#0d73b2",
                        "#f19a14",
                        "#d13358",
                        "#b24c0d",
                        "#DFBE99",
                        "#C879FF",
                        "#DEC5E3",
                        "#0D1821",
                        "#F9CB40"
                    ],
                    files: [],
                    modalIsOpen: false,
                    modalMode: null,
                    modalMessage: null,
                    bulkBuyModalIsOpen: false,
                    bulkAllocateModalIsOpen: false,
                    translations: null,

                    dataFilters: [],
                    groupings: [],
                }
            }

            getGroupings = () => {
                return request(true).get(endpoints.COMPANIES_HOUSE_ALERTS_GROUPINGS).then(e => {
                    this.setState({
                        groupings: e.data,
                        group: e.data.find(_ => _.default)
                    }, () => {
                        this.query();
                    })
                });
            }

            getDates = () => {
                return request(true).get(endpoints.COMPANIES_HOUSE_ALERTS_GET_DATES).then(r => {
                    this.setState({
                        dates: r.data.dates,
                        date: r.data.dates[0].raw
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            setGroup = (field) => {
                this.setState({
                    group: field
                }, () => {
                    this.query();
                });
            }

            setDate = (ev) => {
                this.setState({
                    date: ev.target.value
                }, () => {
                    this.query();
                });
            }

            filter = (field, value) => {
                this.setState({
                    filters: {
                        ...this.state.filters,
                        [field]: value
                    }
                }, () => {
                    this.query();
                });
            }

            filterStats = (field, value) => {
                    if (!this.state.filterArrays[field]) { this.state.filterArrays[field] = {} }
                    this.filterArray(field, value);
            }

            filterArray = (field, value) => {
                this.setState(prevState => {
                    const newValue = Array.isArray(value) ? value : [value];
            
                    const newFilterArrays = {
                        ...prevState.filterArrays,
                        [field]: {
                            ...(prevState.filterArrays[field] || {})
                        }
                    };
            
                    if (typeof value === 'string') {
                        const currentValue = newFilterArrays[field][value];
                        newFilterArrays[field][value] = currentValue ? false : true;
                    } else if (Array.isArray(value)) {
                        newValue.forEach(val => {
                            newFilterArrays[field][val] = true;
                        });
            
                        Object.keys(newFilterArrays[field]).forEach(key => {
                            if (!newValue.includes(key)) {
                                delete newFilterArrays[field][key];
                            }
                        });
                    }
            
                    const newFilter = [];
                    Object.keys(newFilterArrays[field]).forEach(key => {
                        if (newFilterArrays[field][key]) {
                            newFilter.push(key);
                        }
                    });
            
                    return {
                        filterArrays: newFilterArrays,
                        filters: {
                            ...prevState.filters,
                            [field]: newFilter
                        }
                    };
                }, () => {
                    this.query();
                });
            }
            
            

            query = () => {
                if (!this.state.group) return
                this.setState({
                    dataReady: false,
                }, () => {
                    request(true).get(endpoints.COMPANIES_HOUSE_ALERTS_SUMMARY, {
                        params: {
                            date: this.state.date,
                            filter: this.state.filters,
                            group: this.state.group?.target_column
                        },
                        doesCancel: true,
                    }).then(r => {
                        this.setState({
                            data: r.data,
                            dataReady: true
                        }, () => {
                            this.generateGraphData();
                        });
                    });
                })
            }

            generateGraphData = () => {
                //console.log(this.state.data);
                let data = [
                    // {
                    //     name: 'Unknown'
                    // },
                    {
                        name: 'Dissolved'
                    },
                    {
                        name: 'Dormant'
                    },
                    {
                        name: 'Liquidation / Receivership'
                    },
                    {
                        name: 'In Administration'
                    },
                    {
                        name: 'Active - proposal to strike off'
                    },
                    {
                        name: 'Active'
                    },
                ];

                data = data.reverse();

                this.state.data.keys.map(key => {
                    console.log(this.state.data, key)
                    Object.keys(this.state.data.data[key]).map((item, index) => {
                        data[index][key] = this.state.data.data[key][item];

                        return this.state.data.data[key][item];
                    });

                    return key;
                });

                //console.log(data);

                this.setState({
                    graphData: data
                });
            }

            clearFilters = () => {
                this.setState({
                    filters: {
                        Type: null,
                        Consumption: [],
                        New_Connection: null,
                        IMD: [],
                        Voids_Age_Band: [],
                        Occupier_Score: [],
                        Assigned: null,
                        filteredStats: [],
                    },
                    filterArrays: {
                        IMD: {},
                        Voids_Age_Band: {},
                        Occupier_Score: {},
                        Consumption: {},
                    }
                }, () => {
                    this.query();
                });
            }

            setTableOrGraph = (ev) => {
                this.setState({
                    tableOrGraph: ev.target.value
                });
            }

            componentDidMount() {
                const _first = this.getDates();
                useWSProduct('companies_house_alerts');// eslint-disable-line
                request(true).get('/translate?key=companies_house_alerts').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                const _second = this.getGroupings();
                Promise.all([_first, _second]).finally(() => {
                    this.query()
                })
            }

            openUploadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'upload',
                })
            }

            openDownloadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'download',
                });
            }

            closeModal = () => {
                this.setState({
                    modalIsOpen: false,
                })
            }

            openBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: true
                });
            }

            closeBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: false
                });
            }

            openBulkAllocateModal = () => {
                this.setState({
                    bulkAllocateModalIsOpen: true
                });
            }

            closeBulkAllocateModal = () => {
                this.setState({
                    bulkAllocateModalIsOpen: false
                });
            }

            componentWillUnmount() {
                useWSProduct('companies_house_alerts', 'unlisten');// eslint-disable-line
            }

            render () {
                const totals = Array(8).fill(0);

                return (
                    <div className="grid grid-gap-15" id="void-sales-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title={this.state.translations ? this.state.translations['module_title'] : ''} iconClass={ProductIcons.BusinessIcon.props.className} titleRight={
                                <div style={{display: 'flex'}}>
                                    { hasPermission('CHA', 'DOWNLOAD') ?
                                        <button className="navigate" onClick={this.openDownloadModal}>Download Return File</button>
                                    : null }
                                    { hasPermission('CHA', 'UPLOAD') ?
                                        <button className="navigate" onClick={this.openUploadModal}>Issue Input File</button>
                                    : null }
                                    <Link className="navigate" to="/portal/companies-house-alerts/search">
                                        To Search
                                    </Link>
                                    { (hasPermission('CHA', 'WORKFLOW')) ?
                                        <Link className="navigate" to="/portal/companies-house-alerts/workflow">
                                            To Workflow
                                        </Link>
                                    : null }
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="CompaniesHouseAlerts">
                                <div className="grid-overview">
                                    <div className="left">
                                        <div className="data-row file-date">
                                            <div className="data-row-title">
                                                File Date
                                            </div>
                                            <div className="data-row-field">
                                                <select onChange={this.setDate} value={ this.state.date }>
                                                    { this.state.dates.map(item => {
                                                        return (
                                                            <option key={item.raw} value={item.raw}>
                                                                {item.pretty}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="table-format">
                                            <div className={`data-row group-list ${this.state.group}-active`}>
                                                <div className="data-row-title">
                                                    Group by:
                                                </div>
                                                <div className="data-row-field">
                                                    <DataGroupings groupings={this.state.groupings} group={this.state.group} setGroup={this.setGroup} />
                                                </div>
                                            </div>
                                            <div className="data-row display-type">
                                                <select onChange={ this.setTableOrGraph } value={ this.state.tableOrGraph }>
                                                    <option value="table">
                                                        Table
                                                    </option>
                                                    <option value="graph">
                                                        Graph
                                                    </option>
                                                </select>
                                                <ProductChartInfo path={window.location.pathname} />
                                            </div>
                                        </div>
                                        <div className={`cha-table-or-graph using-${this.state.tableOrGraph}`}>
                                            { this.state.tableOrGraph === 'table' ?
                                                <table className="table borders">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th colSpan="8" style={{ borderTopRightRadius: '5px' }}>
                                                                Type
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                { this.state.group.title ?? "" }
                                                            </th>
                                                            <th>
                                                                <p>Active</p>
                                                            </th>
                                                            <th>
                                                                <p>Active - proposal to strike off</p>
                                                            </th>
                                                            <th>
                                                                <p>In Administration</p>
                                                            </th>
                                                            <th>
                                                                <p>Liquidation / Receivership</p>
                                                            </th>
                                                            <th>
                                                                <p>Dormant</p>
                                                            </th>
                                                            <th>
                                                                <p>Dissolved</p>
                                                            </th>
                                                            {/* <th>
                                                                <p>Unknown</p>
                                                            </th> */}
                                                            <th className="no-radius bold">
                                                                Total
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { this.state.data.keys.map(item => {
                                                            const total = this.state.data.data[item]['Active'] + 
                                                            this.state.data.data[item]['Active - proposal to strike off'] + 
                                                            this.state.data.data[item]['In Administration'] +
                                                            this.state.data.data[item]['Liquidation / Receivership'] +
                                                            this.state.data.data[item]['Dormant'] +
                                                            this.state.data.data[item]['Dissolved']
                                                            // this.state.data.data[item]['Unknown']

                                                            console.log('total', total)

                                                            totals[0] += this.state.data.data[item]['Active'];
                                                            totals[1] += this.state.data.data[item]['Active - proposal to strike off'];
                                                            totals[2] += this.state.data.data[item]['In Administration'];
                                                            totals[3] += this.state.data.data[item]['Liquidation / Receivership'];
                                                            totals[4] += this.state.data.data[item]['Dormant'];
                                                            totals[5] += this.state.data.data[item]['Dissolved'];
                                                            // totals[6] += this.state.data.data[item]['Unknown'];
                                                            totals[6] += total;
                                                            console.log(totals)

                                                            return (
                                                                <tr key={`summary-data-${Math.random()}`}>
                                                                    <td>
                                                                        { item !== '' ? item : 'Null' }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['Active']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['Active - proposal to strike off']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['In Administration']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['Liquidation / Receivership']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['Dormant']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['Dissolved']) }
                                                                    </td>
                                                                    {/* <td>
                                                                        { localised.format(this.state.data.data[item]['Unknown']) }
                                                                    </td> */}
                                                                    <td className="bold">
                                                                        { localised.format(total) }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr key={`summary-data-${Math.random()}`} className="bold">
                                                            <td>
                                                                Total
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[0]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[1]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[2]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[3]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[4]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[5]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[6]) }
                                                            </td>
                                                            {/* <td>
                                                                { localised.format(totals[7]) }
                                                            </td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            :
                                                this.state.dataReady ?
                                                    <ResponsiveContainer>
                                                        <BarChart
                                                            data={this.state.graphData}
                                                            margin={{ bottom: 20 }}
                                                            barSize={50}
                                                        >
                                                            <Legend  wrapperStyle={{position: 'relative', marginTop: 10, marginBottom: 50}} iconType="circle" iconSize={11}/>
                                                            <XAxis interval={0} tick={CustomizedAxisTickMultiLine} dataKey="name" label={{color: 'red'}} />
                                                            <YAxis type="number" allowDecimals={false} tick={{ fontSize: 7 }} tickFormatter={ item => { return localised.format(item) }}/>
                                                            <CartesianGrid />
                                                            {this.state.data.keys.map((item, index) => {
                                                                return (
                                                                    <Bar key={ Math.random() } dataKey={item} fill={ this.state.colours[index] } stackId="a" />
                                                                )
                                                            })}
                                                            <Tooltip cursor={false} formatter={ (value, name, props) => { return localised.format(value) }}/>
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="right">
                                        <RightColumn date={this.state.date} customer={this.state.customer} filterArrays={this.state.filterArrays} filterStats={this.filterStats} />
                                        {/* <div className="filters"> */}
                                            {/* <div className="filter-title">
                                                <div className="title">Filters</div>
                                                <div className="clear" onClick={ this.clearFilters }>Clear All</div>
                                            </div> */}
                                            {/* <ProductDataFilter applyFilter={this.filter} filterState={this.state.filters} dataFilterEndpoint={endpoints.COMPANIES_HOUSE_ALERTS_DATA_FILTERS} /> */}
                                            
                                        {/* </div> */}
                                        { (hasPermission('CHA', 'BULK-BUY')) ?
                                            <button className="button bulk-buy-button" onClick={this.openBulkBuyModal}>
                                                Create Download File
                                            </button>
                                        : '' }
                                        { (hasPermission('CHA', 'ALLOCATE')) ?
                                            <button className="button bulk-buy-button" onClick={this.openBulkAllocateModal}>
                                                Allocate
                                            </button>
                                        : '' }
                                    </div>
                                </div>
                            </div>
                        </Container>
                        <S3Modal showModal={this.state.modalIsOpen} closeModal={this.closeModal} modalMode={this.state.modalMode} listingUrl={endpoints.COMPANIES_HOUSE_ALERTS_FILE_UPLOAD} uploadUrl={endpoints.COMPANIES_HOUSE_ALERTS_FILE_GET}/>
                        <BulkBuy
                        SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
                        SentenceSecond={() => <></>}
                        buyButtonText="Create File" completeOnFirstStep={true} workflowUsersEndpoint={endpoints.COMPANIES_HOUSE_ALERTS_WORKFLOW_USERS} bulkEndpoint={endpoints.COMPANIES_HOUSE_ALERTS_SUMMARY_BULK} modalIsOpen={this.state.bulkBuyModalIsOpen} closeModal={this.closeBulkBuyModal} filters={this.state.filters} date={this.state.date}/>
                        <BulkAllocate workflowUsersEndpoint={endpoints.COMPANIES_HOUSE_ALERTS_WORKFLOW_USERS} bulkEndpoint={endpoints.COMPANIES_HOUSE_ALERTS_SUMMARY_BULK} modalIsOpen={this.state.bulkAllocateModalIsOpen} closeModal={this.closeBulkAllocateModal} filters={this.state.filters} date={this.state.date}/>
                        <HelpVideo video={session.company.cha_summary_video_url}/>
                        <Footer/>
                    </div>
                )
            }
        }
    )
)
