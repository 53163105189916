import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './occupierCOTAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import {OccupierCOTAlertTopContent, OccupierFootprintEvidenceSecond,OccupierFootprintEvidenceFirst, OccupierCOTAlertRight} from './sharedComponents'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
    withRouter(
        class VoidCOTAlertsSearch extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    translations: [],
                }
            }

            componentDidMount() {
                useWSProduct('void_cot_alerts');// eslint-disable-line
                request(true).get('/translate?key=occupier_cot_alert').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
            }

            accountNumberChange = (event) => {
                this.setState({
                    accountNumber: event.target.value
                });
            }

            getData = () => {
                request(true).get(`${endpoints.OCCUPIER_COT_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    console.log(r.data.customer);
                    if (!r.data.customer) window.alert('Account not found.');
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null && r.data.customer.Occupier_Score > 0,
                        shouldRefreshMap: true,
                    }, () => {
                        this.setState({ shouldRefreshMap: false });
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            purchaseCustomer = () => {
                request(true).post(`${endpoints.OCCUPIER_COT_ALERTS_BUY_CUSTOMER}`, {
                    "customer": this.state.customer.Account_Number
                }).then(r => {
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer.Date_Bought === null
                    });
                }).catch(e => {
                    console.log(e);
                })
            }

            searchKeyDown = (e) => {
                if (e.which === 13) {
                    this.getData();
                }
            }

            componentWillUnmount() {
                useWSProduct('void_cot_alerts', 'unlisten');// eslint-disable-line
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="void-cot-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title={`${this.state.translations['module_title'] ? this.state.translations['module_title'] : ''} - Search`} titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Link className="navigate" to={ this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/' : '/portal/occupier-cot-alerts/' }>
                                        To Overview
                                    </Link>
                                    { (hasPermission('OCCA', 'WORKFLOW')) ?
                                        <Link className="navigate" to={ this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/workflow' : '/portal/occupier-cot-alerts/workflow' }>
                                            To Workflow
                                        </Link>
                                    : null }
                                    <Link className="navigate" to="/portal/occupier-cot-alerts/report">
                                        To Report
                                    </Link>
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="occa">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['account_number'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } onChange={ this.accountNumberChange } onKeyPress={ this.searchKeyDown }/>
                                        </div>
                                        <OccupierCOTAlertTopContent data={this.state} />
                                    </div>
                                    <div className="right-grid">
                                        <button className="search-button" onClick={ this.getData }>
                                            Search
                                        </button>
                                        { (!hasPermission('OCCA', 'PURCHASE') && this.state.purchasable) ?
                                            <button className="buy-button disabled" disabled={true}>
                                                Purchase Customer
                                            </button>
                                        :
                                            <button className="buy-button" disabled={ !this.state.purchasable} onClick={ this.purchaseCustomer }>
                                                { this.state.purchasable === true || this.state.purchasable === null ? "Purchase Customer" :
                                                    this.state.customer.Bought_By ?
                                                        `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                    :
                                                        this.state.customer.Date_Bought ?
                                                            `Purchased on ${this.state.customer.Date_Bought}`
                                                        :
                                                            'Unavailable for Purchase'
                                                }
                                            </button>
                                        }
                                        <OccupierCOTAlertRight data={this.state} />
                                    </div>
                                </div>
                                <div className="secondary-grid">
                                    <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null } />
                                    <OccupierFootprintEvidenceFirst data={this.state} />
                                    <OccupierFootprintEvidenceSecond data={this.state} />
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

