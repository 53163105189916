import React from 'react'
import GenericDataRow from '../../../GenericPageElements/GenericDataRow'
import { GenericTable } from '../VacantCOTAlerts/sharedComponents'

export const CompanyInfo = ({ translations, data }) => {
  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
            <GenericDataRow title={translations["company_name"]} contentColour={'var(--white)'}>{data['Company_Name']}</GenericDataRow>
          </div>
          <GenericDataRow title={translations["company_number"]} contentColour={'var(--white)'}>{data['Company_Number']}</GenericDataRow>
          <GenericDataRow title={translations["sic_code"]} contentColour={'var(--white)'}>{data['SIC_Code']}</GenericDataRow>
        </div>
        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <GenericDataRow title={translations["website"]} contentColour={'var(--white)'}>{data['Website']}</GenericDataRow>
          <GenericDataRow title={translations["landline"]} contentColour={'var(--white)'}>{data['Landline']}</GenericDataRow>
          <GenericDataRow title={translations["mobile"]} contentColour={'var(--white)'}>{data['Mobile']}</GenericDataRow>
          <GenericDataRow title={translations["occupier_score"]} contentColour={'var(--white)'}>{data['Occupier_Score']}</GenericDataRow>
        </div>
      </div>
    </>
  )
}

export const GoogleEvidence = ({data}) => {
  const tableFormat = [['Company','Google_Company'],['Address','Google_Address'],['Business Status', 'Google_Status'],['Phone','Google_Phone'],['First Review Date','Google_First_Date'],['Last Review Date','Google_Date']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Google" align={'left'} rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const CompaniesHouseEvidence = ({data}) => {
  const tableFormat = [['Company','Comp_Hse_Company'],['Address','Comp_Hse_Address'],['Business Status','Comp_Hse_Status'],['SIC','Comp_Hse_SIC'],['Incorporated Date','Comp_Hse_Inc_Date'],['Last Statement Date','Comp_Hse_LS_Date']];
  return (
    <>
      <GenericTable firstColWidth={120} title="Companies House" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const YellEvidence = ({data}) => {
  const tableFormat = [['Company','Yell_Company'],['Address','Yell_Address'],['Business Status','Yell_Status'],['Phone','Yell_Phone'],['Website','Yell_Website'],['Email','Yell_Email']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Yell.Com" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const AddressDataEvidence = ({data}) => {
  const tableFormat = [['Company','AD_Name'],['UPRN Address','AddressBase_Address'],['UPRN','UPRN'],['VOA Number','AD_VOA_No'],['VOA End Date','AD_VOA_End'],['CT Start Date','AD_CT_Start']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Address Data" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const FSAEvidence = ({data}) => {
  const tableFormat = [['Company', 'FSA_Name'],['Address', 'FSA_Address'],['Rating Date', 'FSA_Rating_Date']];
  return (
    <>
      <GenericTable firstColWidth={100} title="FSA" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const BTEvidence = ({data}) => {
  const tableFormat = [['Company', 'BT_Name'],['Address', 'BT_Address'],['Phone', 'BT_Phone']];
  return (
    <>
      <GenericTable firstColWidth={100} title="BT Directory" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const TripAdvisorEvidence = ({data}) => {
  const tableFormat = [['Company', 'TA_Name'],['Address', 'TA_Address'],['Phone', 'TA_Phone']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Trip Advisor" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const DVSAEvidence = ({data}) => {
  const tableFormat = [['Company', 'DVSA_Name'],['Address', 'DVSA_Address'],['Phone', 'DVSA_Phone']];
  return (
    <>
      <GenericTable firstColWidth={100} title="DVSA" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export function CQCEvidence({data}) {
  const tableformat = [['Company', 'CQC_Name'],['Address', 'CQC_Address'],['Phone', 'CQC_Phone']];
  return (
      <GenericTable firstColWidth={100} title="Quality Care Comm" align="left" rows={tableformat} data={data} extraClass="squish super-small-text extra-row-padding"   />
  )
}

export function JustEatEvidence({data}) {
  const tableformat = [['Company', 'JE_Company'],['Address', 'JE_Address'],['Phone', 'JE_Phone']];
  return (
      <GenericTable firstColWidth={100} title="Just Eat" align="left" data={data} rows={tableformat} extraClass="squish super-small-text extra-row-padding"  />
  )
}

export function One92Evidence({data}) {
  const tableformat = [['Company', 'One92_Company'],['Address', 'One92_Address'],['Phone', 'One92_Phone']];
  return (
      <GenericTable firstColWidth={100} title="192.com" align="left" data={data} rows={tableformat} extraClass="squish super-small-text extra-row-padding" />
  )
}

export function FacebookEvidence({data}) {
  const tableformat = [['Company', 'FB_Company'],['Address', 'FB_Address'],['Phone', 'FB_Phone']];
  return (
      <GenericTable firstColWidth={100} title="Facebook" align="left" data={data} rows={tableformat} extraClass="squish super-small-text extra-row-padding" />
  )
}
