import React, { useEffect, useState, useMemo } from 'react'
import Page from '../../../components/page/page'
import PropertyMap from '../../../components/property-map/property-map';
import request from '../../../helpers/request';
import { DataRow, Title, matchColour } from '../CompaniesHouseAlerts/sharedComponents';
import { CotValidationTitle } from './cotValidation'
import { CotValidationAccountDetails, CotValidationClientDetails, CotValidationMatchedDetails, CotValidationMatchStatus, CotValidationOccupierFootprint, SearchRow, CotValidationManualReviewClient, CotValidationManualReviewExp, CotValidationManualReviewTu, CotValidationManualReviewSelected } from './cotValidationShared'
import GenericDataRow, { GenericDataRowTitle } from '../../../GenericPageElements/GenericDataRow';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import './cotValidation.scss';
import endpoints from '../../../helpers/endpoints';
import moment from 'moment';

export default function CotValidationManualReview() {
    const [translations, setTranslations] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [customer, setCustomer] = useState(null)
    const [inputField, setInputField] = useState({})
    const [selected, setSelected] = useState()
    const [isChecked, setIsChecked] = useState({});
    const [modalMode, setModalMode] = useState(false)
    const [workflowItems, setWorkflowItems] = useState([])
    const [selectedSPID, setSelectedSPID] = useState('');
    const currentWorkflowPosition = useMemo(() => {
        if (!selectedSPID) return 0
        return workflowItems.indexOf(selectedSPID) + 1
      }, [workflowItems, selectedSPID])

    useEffect(() => {
        request(true).get('/translate?key=cot_validation').then(r => {
            setTranslations(r.data)
        }).catch(e => {
            console.error(e);
        })
    }, [])

    const getWorkflowItems = () => {
        setWorkflowItems([])
        setCustomer({})
        setSelectedSPID('')
        request(true).get(endpoints.COT_VALIDATION_GET_WORKFLOW_MANUAL_REVIEW).then(e => {
            setWorkflowItems(e.data)
            console.log(e.data.length)
            if (e.data.length > 0) setSelectedSPID(e.data[0])
        })
    }

    useEffect(() => {
        getWorkflowItems()
    }, [])

    useEffect(() => {
        getData()
      }, [selectedSPID])

    const wfDir = (dir = 'prev') => {
        if (dir === 'prev') {
            if (currentWorkflowPosition !== 1) setSelectedSPID(workflowItems[currentWorkflowPosition - 2])
        } else if (currentWorkflowPosition !== workflowItems.length) setSelectedSPID(workflowItems[currentWorkflowPosition])
    }

    const getData = () => {
        if (!selectedSPID) return
        request(true).get(endpoints.COT_VALIDATION_GET_MANUAL_REVIEW, {
            params: {
                search: selectedSPID
            }
        }).then(e => {
            if (!e.data.customer) window.alert('Account not found.');
            setCustomer(e.data.customer)
            setSelected({
                App_Title:  e.data.customer?.App_Title ? e.data.customer.App_Title : null,
                App_Forename:  e.data.customer?.App_Forename ? e.data.customer.App_Forename : null,
                App_Middle_Name:  e.data.customer?.App_Middle_Name ? e.data.customer.App_Middle_Name : null,
                App_Surname:  e.data.customer?.App_Surname ? e.data.customer.App_Surname : null,
                App_Date_Of_Birth:  e.data.customer?.App_Date_Of_Birth ? e.data.customer.App_Date_Of_Birth : null,
            })
            setIsChecked({})
        })
    }

    const updateAppData = () => {
        if ((customer.Client_Date_Of_Birth ? isChecked.Date_Of_Birth : true) && isChecked.Surname && isChecked.Forename && isChecked.Title) {
            request(true).post(endpoints.COT_VALIDATION_UPDATE_MANUAL_REVIEW, {
                search: selectedSPID,
                selected: selected,
                customer: customer
            }).then(r => {
                getWorkflowItems()
            })
        }
    }

    const updateTU = (event) => {
        if (event instanceof Date) {
            const date = moment(event);
            if (date.isValid()) {
                setCustomer((customer) => {
                    const updatedInput = { ...customer, 'TU_Date_Of_Birth': date.format('DDMMMYYYY').toUpperCase()}
                    console.log(updatedInput)
                    return updatedInput;
                })
            }
        } else {
            setCustomer((customer) => {
                const id = event.target.id.substring(event.target.id.indexOf('_') + 1)
                const updatedInput = { ...customer, [id]: event.target.value };
                setInputField(event.target.id)
                return updatedInput;
            });
        }
    }

    const updateSelected = (source, data, column = null) => {
        if (!data) return

        setSelected((prevSelected) => {
            const updatedSelected = { ...prevSelected };
            
            if (column) {
                setIsChecked({...isChecked, [column]: source})
                const index = `${source}_${column}`
                updatedSelected[`App_${column}`] = data[index];
            } else {
                const newChecked = isChecked;
                for (const property in updatedSelected) {
                    const index = `${source}_${property.substring(property.indexOf('_') + 1)}`
                    if (data[index]) {
                        updatedSelected[property] = data[index]
                        newChecked[property.substring(property.indexOf('_') + 1)] = source
                    }
                }
                setIsChecked(newChecked);
            }
    
            return updatedSelected;
        });
    }

    const closeBulkAllocateModal = () => {
        setModalMode(false)
    }

    return (
        <Page title={`${translations['module_title'] || ''} - Manual Review`} titleRight={<CotValidationTitle showS3={false} showManualReviewLink={false} showAllocate={true} setModalMode={setModalMode}/>}>
            <div className="CotValidation">
                <div className="grid-overview">
                    <div className="left" style={{gap: '10px', gridGap: '10px'}}>
                        <div className="grid grid-columns-2 grid-gap-15">
                            <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
                                <Title title="Client Details" />
                                <div style={{display: 'grid', gridAutoRows: '1fr', gap: 10, height: '100%'}}>
                                    <div style={{display: 'grid', gridTemplateColumns: 'auto max-content', gap: 10}}>
                                        <GenericDataRow title={<div style={{textAlign: 'center'}}>Account Number</div>} contentColour={'var(--white)'}>
                                            <input value={selectedSPID} disabled={true} style={{width: '100%', outline: 'none', border: 'none', backgroundColor: 'var(--white)'}} />
                                        </GenericDataRow>
                                        <div style={{display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', height: '37px'}}>
                                            <div style={{cursor: 'pointer'}} onClick={() => wfDir('prev')}><i className="colour-primary fas fa-angle-left"></i></div>
                                            <span style={{width: 40, textAlign: 'center'}}>{currentWorkflowPosition}/{workflowItems.length}</span>
                                            <div style={{cursor: 'pointer'}} onClick={() => wfDir('next')}><i className="colour-primary fas fa-angle-right"></i></div>
                                        </div>
                                    </div>
                                    <DataRow translations={translations} customer={customer} value={'Client_Title'} title={'title'} />
                                    <DataRow translations={translations} customer={customer} value={'Client_Forename'} title={'forename'} />
                                    <DataRow translations={translations} customer={customer} value={'Client_Surname'} title={'surname'} />
                                    <DataRow translations={translations} customer={customer} value={'Client_Date_Of_Birth'} title={'date_of_birth'} />
                                </div>
                            </div>
                            <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
                                <Title title="Matched Details" />
                                <div style={{display: 'grid', gridAutoRows: '1fr', gap: 10, height: '100%'}}>
                                    <DataRow 
                                        title={'status'} 
                                        translations={translations} 
                                        customValue={customer ? customer.Match_Status ? `${customer.Match_Status}` : '' : ''} 
                                    />
                                    <DataRow translations={translations} customer={customer} value={'App_Title'} title={'title'} />
                                    <DataRow translations={translations} customer={customer} value={'App_Forename'} title={'forename'} />
                                    <DataRow translations={translations} customer={customer} value={'App_Surname'} title={'surname'} />
                                    <DataRow translations={translations} customer={customer} value={'App_Date_Of_Birth'} title={'date_of_birth'} />
                                </div>
                            </div>
                        </div>
                        <DataRow style={{height: '37px'}} translations={translations} customer={customer} customValue={customer ? `${customer.Client_Address}, ${customer.Client_Postcode}` : ''} title={'address'} />
                    </div>
                    <div className="right">
                        <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
                            <Title title="Match Status" />
                            <div style={{display: 'grid', gridAutoRows: '1fr', gap: 10, height: '100%'}}>
                                <Title colour={customer ? matchColour(customer.Full_Status) : null} title={customer ? customer.Full_Status || 'N/A' : 'N/A'} disabled={true} />
                                <Title colour={customer ? matchColour(customer.Forename_Status) : null} title={customer ? customer.Forename_Status || 'N/A' : 'N/A'} disabled={true} />
                                <Title colour={customer ? matchColour(customer.Surname_Status) : null} title={customer ? customer.Surname_Status || 'N/A' : 'N/A'} disabled={true} />
                                <Title colour={customer ? matchColour(customer.DOB_Status) : null} title={customer ? customer.DOB_Status || 'N/A' : 'N/A'} disabled={true} />
                                <Title colour={customer ? matchColour(customer.Landline_Status) : null} title={customer ? customer.Landline_Status || 'N/A' : 'N/A'} disabled={true} />
                                <Title colour={customer ? matchColour(customer.Mobile_Status) : null} title={customer ? customer.Mobile_Status || 'N/A' : 'N/A'} disabled={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-columns-4 grid-gap-15">
                    <CotValidationManualReviewClient data={customer ? customer : {}} translations={translations} updateSelected={updateSelected} isChecked={isChecked} />
                    <CotValidationManualReviewExp data={customer ? customer : {}} translations={translations} updateSelected={updateSelected} isChecked={isChecked} />
                    <CotValidationManualReviewTu data={customer ? customer : {}} translations={translations} updateSelected={updateSelected} isChecked={isChecked} gatherInput={updateTU} inputField={inputField} />
                    <CotValidationManualReviewSelected data={customer ? customer : {}} selected={selected ? selected : {}} translations={translations} updateAppData={updateAppData} isChecked={isChecked} />
                </div>
            </div>
            <BulkAllocate workflowUsersEndpoint={endpoints.COT_VALIDATION_WORKFLOW_USERS} bulkEndpoint={endpoints.COT_VALIDATION_MANUAL_REVIEW_BULK} modalIsOpen={modalMode} closeModal={closeBulkAllocateModal} />
        </Page>
    )
}
