import React, { useState, useEffect } from 'react'
import Container from '../../../components/container/container';
import './ltapAnalysis.scss';
import { Link, withRouter } from 'react-router-dom';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints';
import { Text } from 'recharts';
import session from '../../../stores/session';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import HelpVideo from '../../../components/help-video/helpVideo';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter';
import Modal from 'react-modal';
import GenericDataChart from '../../../GenericPageElements/GenericDataChart'
import GenericProductFilterContainer from '../../../GenericPageElements/GenericProductFilterContainer'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import "../../../components/cot-alert-bulk-buy/bulkBuy.scss"

const localised = Intl.NumberFormat('en-GB');

const CustomizedAxisTickMultiLine = ({ x, y, payload }) => {
    return (
        <Text x={x} y={y} width={80} fontSize={14} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
    )
};

export default function LTAPAnalysis() {
    const [dataReady, setDataReady] = useState(false)
    const [dates, setDates] = useState([])
    const [date, setDate] = useState(undefined)
    const [data, setData] = useState(null)
    const [group, setGroup] = useState('')
    const [filters, setFilters] = useState({})
    const [bulkBuyModalIsOpen, setBulkBuyModalIsOpen] = useState(false)
    const [bulkAllocateModalIsOpen, setBulkAllocateModalIsOpen] = useState(false)
    const [translations, setTranslations] = useState({})

    const toggleGroup = (field) => {
        if (group == field) {
            setGroup('')
        } else {
            setGroup(field)
        }
    }

    const filter = (field, value) => {
        setFilters({
            ...filters,
            [field]: value
        })
    }
    
    useEffect(() => {
        query()
    }, [group, filters])

    const query = () => {
        setDataReady(false)
        request(true).get(endpoints.LTAP_ANALYSIS_SUMMARY, {
            params: {
                filter: filters,
                group: group?.target_column,
            },
            doesCancel: true,
        }).then(r => {
            setData(r.data)
            setDataReady(true)
        });
    }
    
    const clearFilters = () => {
        setFilters({})
    }

    useEffect(() => {
        request(true).get('/translate?key=ltap_analysis').then(r => {
            setTranslations(r.data)
        }).catch(e => {
            console.error(e);
        })
    }, [])
    
    useEffect(() => {
        query()
    }, [])

    const openBulkBuyModal = () => {
        setBulkBuyModalIsOpen(true)
    }

    const closeBulkBuyModal = () => {
        setBulkBuyModalIsOpen(false)
    }

    const openBulkAllocateModal = () => {
        setBulkAllocateModalIsOpen(true)
    }

    const closeBulkAllocateModal = () => {
        setBulkAllocateModalIsOpen(false)
    }

    return (
        <GenericPageContainer title={translations['module_title'] || ''} titleRight={
            <div style={{ display: 'flex' }}>
                <Link className="navigate" to="/portal/ltap-analysis/search">
                    To Search
                </Link>
                {(hasPermission('LTAP_ANALYSIS', 'WORKFLOW')) ?
                    <Link className="navigate" to="/portal/ltap-analysis/workflow">
                        To Workflow
                    </Link>
                    : null}
            </div>
        }>
            <Container>
                <div className="lta">
                    <div className="data-row file-date">
                        <div className="data-row-title">
                            {translations ? translations['file_date'] : ''}
                        </div>
                        <div className="data-row-field">
                            <select onChange={e => setDate(e.target.value)} value={''}>
                                {dates.map(item => {
                                    return (
                                        <option key={item.raw} value={item.raw}>
                                            {item.pretty}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='grid-overview'>
                        <GenericDataChart
                            dataReady={dataReady}
                            data={data}
                            selectedGroupBy={group}
                            setSelectectGroupBy={toggleGroup}
                            groupingsEndpoint={`${endpoints.LTAP_ANALYSIS_GROUPINGS}`}
                            sizes={{ chart: 500, chartContainer: 500, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' } }}
                            xAxisProps={{ interval: 0 }}
                            displayModeInitial='graph'
                            chartColours={["#445469"]}
                            customBarTick={CustomizedAxisTickMultiLine}
                        />
                        <GenericProductFilterContainer clearFilters={clearFilters} style={{ height: 'unset', position: 'sticky', top: 10 }}>
                            <ProductDataFilter applyFilter={filter} filterState={filters} dataFilterEndpoint={endpoints.LTAP_ANALYSIS_DATA_FILTERS} />
                            <div className='grid grid-columns-2 grid-gap-5 mta'>
                                {(hasPermission('LTAP_ANALYSIS', 'BULK-BUY')) ?
                                    <button className="button bulk-buy-button" onClick={openBulkBuyModal} style={{ marginTop: 15 }}>
                                      Create Download File
                                    </button>
                                    : ''}
                                {(hasPermission('LTAP_ANALYSIS', 'ALLOCATE')) ?
                                    <button className="button bulk-buy-button" onClick={openBulkAllocateModal} style={{ marginTop: 15 }}>
                                        Allocate
                                    </button>
                                    : ''}
                            </div>
                        </GenericProductFilterContainer>
                    </div>
                </div>
            </Container>
            <BulkBuy
              SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
              SentenceSecond={() => <></>}
              buyButtonText="Create File" completeOnFirstStep={true} workflowUsersEndpoint={endpoints.LTAP_ANALYSIS_WORKFLOW_USERS} bulkEndpoint={endpoints.LTAP_ANALYSIS_SUMMARY_BULK} modalIsOpen={bulkBuyModalIsOpen} closeModal={closeBulkBuyModal} filters={filters} date={date}/>
            <BulkAllocate workflowUsersEndpoint={endpoints.LTAP_ANALYSIS_WORKFLOW_USERS} bulkEndpoint={endpoints.LTAP_ANALYSIS_SUMMARY_BULK} modalIsOpen={bulkAllocateModalIsOpen} closeModal={closeBulkAllocateModal} filters={filters} />
            <HelpVideo video={session.company.LTA_video_url} />
        </GenericPageContainer>
    )
}
