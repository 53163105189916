import React, { useCallback, useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactSelect from 'react-select'
import endpoints from '../../../helpers/endpoints'
import request from '../../../helpers/request'
import { GenericTable } from '../VacantCOTAlerts/sharedComponents'
import Button from '../../../components/large-link-button/largeLinkButton'
import ReactModal from 'react-modal'
import Checkbox from '@material-ui/core/Checkbox';
import './MixedUse.scss';
import hasPermission from '../../../helpers/permissions'
import PropertyMap from '../../../components/property-map/property-map'

export const Summary = ({workflow = false, translations = {}}) => {
  const [waltoSpid, setWaltoSpid] = useState();
  const [altoSpid, setAltoSpid] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const getSummaryData = (type) => {
    setData([])
    setLoading(true);
    request(true).get(endpoints.MIXED_USE_SEARCH_BY_SPID, {
      params: {
        'walto_spid': waltoSpid,
        'alto_spid': altoSpid,
        'type': type
      },
    }).then(r => {
      setLoading(false)
      if (r.data.length === 0) {
        setData([])
        type === "walto" ? setAltoSpid() : setWaltoSpid();
        window.alert('Could not find SPID'); 
        return
      }
      setWaltoSpid(r.data[0]?.WALTO_HIERARCHY_IDENTIFIER);
      setAltoSpid(r.data[0]?.ALTO_HIERARCHY_IDENTIFIER);
      setData(r.data)
    }).catch(() => {
      setLoading(false)
      window.alert('Could not find SPID');
    });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      let type;
      if (event.target.id === "walto-spid") {
        type = "walto"
      } else if (event.target.id === "alto-spid") {
        type = "alto"
      }
      getSummaryData(type)
    }
  }

  return (
    <div className='MU-Shared'>
      <div className='summary'>
        <div className='left-column'>
          <div style={{maxHeight: '90vh', overflowY: 'auto', display: 'block'}}>
            <div className='summary-grid'>
              <div className='summary-column'>
                <div className="data-row">
                  <div className="data-row-title" style={{borderRadius: '5px'}}>
                    {translations['Walto']}
                  </div>
                </div>
                <div className="data-row">
                  <div className="data-row-title" style={{borderRadius: '5px'}}>
                    {translations['Alto']}
                  </div>
                </div>
                <div className="data-row">
                  <div className="data-row-title" style={{borderRadius: '5px'}}>
                    {translations['Council_Tax']}
                  </div>
                </div>
              </div>
              <div className='summary-column'>
                <div className="data-row with-buttons">
                  <div className="data-row-title">
                    {translations['SPID']}
                  </div>
                  <input id="walto-spid" type="text" className="data-row-field" onChange={e => setWaltoSpid(e.target.value)} value={waltoSpid ?? data[0]?.WALTO_HIERARCHY_IDENTIFIER ?? ''} onKeyDown={handleKeyDown} />
                  <span className='fas fa-search colour-primary search-in-line' style={{cursor: 'pointer'}} onClick={() => getSummaryData('walto')}></span>
                </div>
                <div className="data-row with-buttons">
                  <div className="data-row-title">
                    {translations['SPID']}
                  </div>
                  <input id="alto-spid" type="text" className="data-row-field" onChange={e => setAltoSpid(e.target.value)} value={altoSpid ?? data[0]?.ALTO_HIERARCHY_IDENTIFIER ?? ''} onKeyDown={handleKeyDown} />
                  <span className='fas fa-search colour-primary search-in-line' style={{cursor: 'pointer'}} onClick={() => getSummaryData('alto')}></span>
                </div>
                <div className="data-row">
                  <div className="data-row-title">
                    {translations['Ref']}
                  </div>
                  <input type="text" className="data-row-field" disabled={true} value={ data[0] ? data[0].LOCAL_AUTHORITY_REF : ''}/>
                </div>
              </div>
              <div className='summary-column'>
                <div className="data-row">
                  <div className="data-row-title">
                    {translations['Address']}
                  </div>
                  <input type="text" className="data-row-field" disabled={true} value={ data[0] ? data[0].WALTO_ADDRESS : ''}/>
                </div>
                <div className="data-row">
                  <div className="data-row-title">
                    {translations['Address']}
                  </div>
                  <input type="text" className="data-row-field" disabled={true} value={ data[0] ? data[0].ALTO_ADDRESS : ''}/>
                </div>
                <div className="data-row">
                  <div className="data-row-title">
                    {translations['Address']}
                  </div>
                  <input type="text" className="data-row-field" disabled={true} value={ data[0] ? data[0].COUNCIL_TAX_ADDRESS : ''}/>
                </div>
              </div>
              <div className='summary-column'>
                <div className="data-row">
                  <div className="data-row-title">
                    {translations['Occupier']}
                  </div>
                  <input type="text" className="data-row-field" disabled={true} value={ data[0] ? data[0].WALTO_CUSTOMER : ''}/>
                </div>
                <div className="data-row">
                  <div className="data-row-title">
                    {translations['Occupier']}
                  </div>
                  <input type="text" className="data-row-field" disabled={true} value={ data[0] ? data[0].ALTO_CUSTOMER : ''}/>
                </div>
                <div className="data-row">
                  <div className="data-row-title">
                    {translations['URL']}
                  </div>
                  <input type="text" className="data-row-field" disabled={true} style={{color: 'blue'}} value={ data[0] ? data[0].URL : ''} />
                  <a style={{position: 'absolute', left: '0', right: '0', top: '0', bottom: '0', pointerEvents: data[0]?.URL ? 'unset' : 'none' }} href={data[0]?.URL} target='_blank' rel="noreferrer"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='data-table'>
        <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
          <col width={90} />
          <colgroup>
            <col />
            <col />
            <col />
          </colgroup>
          <colgroup>
            <col width={100} />
            <col width={100} />
          </colgroup>
          <colgroup>
            <col width={140} />
            <col width={140} />
          </colgroup>
          <colgroup>
            <col width={100} />
            <col width={100} />
            <col width={100} />
          </colgroup>
          <thead>
            <tr className='centered'>
              <th>{translations['Month']}</th>
              <th colSpan={3} className='category-header'>
                {translations['Address']}
                <div>
                  <th>{translations['CT']}</th>
                  <th>{translations['Alto']}</th>
                  <th>{translations['Walto']}</th>
                </div>
              </th>
              <th colSpan={2} className='category-header'>
                {translations['UPRN']}
                <div>
                  <th>{translations['Alto']}</th>
                  <th>{translations['Walto']}</th>
                </div>
              </th>
              <th colSpan={2} className='category-header'>
                {translations['Occupier']}
                <div>
                  <th>{translations['Alto']}</th>
                  <th>{translations['Walto']}</th>
                </div>
              </th>
              <th>{translations['Name_Comparison']}</th>
              <th>{translations['Status']}</th>
              <th>{translations['Outcome']}</th>
            </tr>
          </thead>
          <tbody>
            {
              data.length > 0 ? 
                data.map(customer => {
                  return (
                    <tr>
                      <td style={{width: '10px'}} className='centered'>{customer.FILE_DATE ? customer.FILE_DATE : ''}</td>
                      <td>{customer.COUNCIL_TAX_ADDRESS}</td>
                      <td>{customer.ALTO_ADDRESS}</td>
                      <td>{customer.WALTO_ADDRESS}</td>
                      <td className='centered'>{customer.ALTO_UPRN}</td>
                      <td className='centered'>{customer.WALTO_UPRN}</td>
                      <td className='centered'>{customer.ALTO_CUSTOMER}</td>
                      <td className='centered'>{customer.WALTO_COMPANY_NAME}</td>
                      <td className='centered'>{customer.NAME_COMPARISON}</td>
                      <td className='centered'>{customer.MIXED_USE_STATUS}</td>
                      <td className='centered'>{customer.OUTPUT}</td>
                    </tr>
                  )
                })
              :
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
            }
          </tbody>
        </table>
        {
          loading ?
            <i style={{position: 'relative', left: '50%', transform: 'translateX(-50%)'}} className='fas fa-loader fa-fw fa-spin'></i>
          : null
        }
      </div>
      <div style={{height: '100%', minHeight: '50vh'}}>
          <PropertyMap address={ data[0]?.ALTO_ADDRESS ? data[0]?.ALTO_ADDRESS : data[0]?.WALTO_ADDRESS ? data[0]?.WALTO_ADDRESS : null } />
        </div>
    </div>
  )
}
