import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useRef, useState } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions'
import { useHistory } from 'react-router-dom';
import { Summary } from './shared'

export default view(function MixedUseManualMatchSearch() {
  const history = useHistory();
  const postcode = history.location?.state?.postcode || null;
  const [translations, setTranslations] = useState({})
  const [bulkModel, setBulkModel] = useState('');
  useEffect(() => {
    request(true).get('/translate?key=wholesaler_gaps')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  return (
    <GenericPageContainer
      containerWidth="92vw"
      title={`${translations['mumm_module_title'] || ''} - Search`}
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}

      titleRight={
        <div>
          <button className="navigate" onClick={() => history.push({pathname: "/portal/mixed-use-manual-match/", state: { postcode: postcode }})}>To Summary</button>
          { hasPermission('MIXED_USE_MANUAL_MATCH', 'WORKFLOW') ?
            <button className="navigate" onClick={() => history.push({pathname: "/portal/mixed-use-manual-match/workflow", state: { postcode: postcode }})}>To Workflow</button>
          : null }
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gap: 10}}>
          <Summary translations={translations} />
        </div>
      </div>
    </GenericPageContainer>
  )
})
