import React from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import request from '../../helpers/request';
import { matchPath, withRouter } from 'react-router-dom';

export default withRouter(({ ...props }) => {
  const match = matchPath(props.history.location.pathname, {
    path: '/admin/portal/companies/:company/translate',
  })
  const {params} = match;
  const [companyId] = React.useState(params.company);
  const [key, setKey] = React.useState('void_sales_alerts');
  const [translations, setTranslations] = React.useState([]);
  const [products, setProducts] = React.useState([])

  const loadProducts = React.useCallback(() => {
    request(true).get("/real-products", {
      params: {
        withTranslations: true
      }
    }).then(r => {
      setProducts(r.data);
    }).catch(e => {
        console.error(e);
    });
  })

  const loadTranslations = React.useCallback(() => {
    request(true).get(`/translate/${companyId}?key=${key}`).then(r => {
      setTranslations(r.data);
    }).catch(e => {
        console.error(e);
    });
  }, [companyId, key]);

  const onInputChange = e => {
    const trans = [].concat(translations);
    trans[e.target.name].override = e.target.value;
    setTranslations(trans);
  }

  const submit = () => {
    const data = {};

    for (const index in translations) {
      if (translations[index].override.trim() !== '') {
        data[translations[index].id] = translations[index].override.trim();
      }
    }

    request(true).post(`/translate/${companyId}?key=${key}`, { data }).then(r => {
      console.log(r);
    }).catch(e => {
        console.error(e);
      });
  }

  React.useEffect(() => {
    loadProducts()
    loadTranslations();
  }, [key, loadTranslations]);

  return (
    <div className="grid grid-gap-20">
      <Navigation/>
      <Container>
        <div className="grid" style={{ gridGap: '15px', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
          { products.filter(p => !!p.product_offering).map(key => (
            <button className="button background-primary colour-white" onClick={ () => setKey(key.product_key) }>
              { key.product_offering.name }
            </button>
          ))}
        </div>
        <table className="table" style={{ marginTop: '15px' }}>
          <thead>
            <tr>
              <th>Module</th>
              <th>Key</th>
              <th>Default</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            { translations.map((item, index) => (
              <tr key={`translation-${index}`}>
                <td>
                  { key }
                </td>
                <td>
                  { item.key }
                </td>
                <td>
                  { item.default }
                </td>
                <td>
                  <input type="text" onChange={ onInputChange } name={index} value={item.override}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button style={{ width: '15%' }} className="button background-primary colour-white" onClick={ submit }>
          Update
        </button>
      </Container>
      <Footer/>
    </div>
  )
})
