import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import hasPermission from "../../../helpers/permissions";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import BatchRunModal from "./BatchRunModal";
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './NonHouseholdGAPS.scss'; 
import ReactModal from 'react-modal'
import { useHistory } from 'react-router-dom';

export default view(function NonHouseholdGAPSSummary() {
  const history = useHistory();
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("");
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [postcode, setPostcode] = useState(history.location?.state?.postcode || null );
  const [S3Model, setS3Model] = useState('');
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [bulkModel, setBulkModel] = useState('');
  const [translations, setTranslations] = useState({})

  useEffect(() => {
    request(true).get('/translate?key=non_household_gaps')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(`${endpoints.NON_HOUSEHOLD_GAPS_DATES}?stage=4`)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate(e.data.dates[0].raw);
      });
  }, []);

  useEffect(() => {
    getData();
  }, [filters, selectedGroupBy, date]);

  const getData = () => {
    if (!date) return;
    request(true)
      .get(`${endpoints.NON_HOUSEHOLD_GAPS_DATA}?stage=4&postcode=${postcode ?? ''}%`, {
        params: {
          date: date,
          filter: filters,
          group: selectedGroupBy,
        },
        doesCancel: true,
      })
      .then((e) => {
        setData(e.data);
      });
  }

  return [
    <GenericPageContainer
      title={`${translations['module_title'] || ''} - Summary`}
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <div>
            <button className="navigate" onClick={() => history.push({pathname: "/portal/non-household-gaps/search", state: { postcode: postcode }})}>To Search</button>
            { hasPermission('NON_HOUSEHOLD_GAPS', 'WORKFLOW') ?
              <button className="navigate" onClick={() => history.push({pathname: "/portal/non-household-gaps/workflow", state: { postcode: postcode }})}>To Workflow</button>
            : null }
          </div>
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow>
      </div>
      <div className='NHG'>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={selectedGroupBy}
          setSelectectGroupBy={setSelectectGroupBy}
          groupingsEndpoint={`${endpoints.NON_HOUSEHOLD_GAPS_GROUPINGS}?stage=4`}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
        />
        <GenericProductFilterContainer clearFilters={() => setFilters({})}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={`${endpoints.NON_HOUSEHOLD_GAPS_FILTERS}?stage=4`}
          />
          <div className='grid grid-columns-1 mta'>
              { hasPermission('NON_HOUSEHOLD_GAPS', 'ALLOCATE') ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate')}>
                      Allocate
                  </button>
              : '' }
          </div>
        </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.NON_HOUSEHOLD_GAPS_FILES}
      uploadUrl={endpoints.NON_HOUSEHOLD_GAPS_FILES}
    />,
    <ReactModal 
    isOpen={postcodeModal} 
    onRequestClose={() => setPostcodeModal(false)}
    className="card bulk-allocate"
    contentLabel="Bulk Allocate COT Alert Data"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Postcode Search</h3>
        <input autoFocus value={postcode} onChange={_ => setPostcode(_.target.value)} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <button className='button compact smaller-text background-primary colour-white' onClick={() => {getData(postcode || undefined); setPostcodeModal(false)}}>Search</button> 
        </div>
      </div>
    </ReactModal>,
    <BulkAllocate workflowUsersEndpoint={endpoints.NON_HOUSEHOLD_GAPS_WF_USERS} bulkEndpoint={endpoints.NON_HOUSEHOLD_GAPS_BULK_BASE} urlParams={{stage: '4', 'postcode': postcode ?? ''}} modalIsOpen={bulkModel === 'allocate'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
    <BatchRunModal modalIsOpen={bulkModel === 'batchrun'} closeModal={() => setBulkModel('')} postcode={postcode} />
  ];
})
