import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useState } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import { useHistory } from 'react-router-dom';
import { Summary } from './shared'
import BatchRunModal from "./BatchRunModal";
import hasPermission from '../../../helpers/permissions';

export default view(function NonHouseholdGAPSSearch() {
  const history = useHistory();
  const postcode = history.location?.state?.postcode || null;
  const [translations, setTranslations] = useState({})
  const [bulkModel, setBulkModel] = useState('');
  useEffect(() => {
    request(true).get('/translate?key=non_household_gaps')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  return (
    <GenericPageContainer
    containerWidth="92vw"

      title={`${translations['module_title'] || ''} - Search`}
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div style={{marginBottom: '10px'}}>
          <button className="navigate" onClick={() => history.push({pathname: "/portal/non-household-gaps/", state: { postcode: postcode }})}>To Summary</button>
          <button className="navigate" onClick={() => history.push({pathname: "/portal/non-household-gaps/workflow", state: { postcode: postcode }})}>To Workflow</button>
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gap: 10}}>
          <Summary translations={translations}/>
        </div>
      </div>
      <BatchRunModal modalIsOpen={bulkModel === 'batchrun'} closeModal={() => setBulkModel('')} postcode={postcode} />
    </GenericPageContainer>    
  )
})
